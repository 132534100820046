var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Thêm mới nhóm câu hỏi","okText":"Thêm mới","cancelText":"Hủy"},on:{"cancel":function () { _vm.$emit('cancel') },"ok":function () { _vm.$emit('create') }}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Tên viết tắt"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'code',
          {
            rules: [{ required: true, message: 'Tên viết tắt không được bỏ trống!' }],
          }
        ]),expression:"[\n          'code',\n          {\n            rules: [{ required: true, message: 'Tên viết tắt không được bỏ trống!' }],\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"Tên nhóm"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],
          }
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],\n          }\n        ]"}]})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }