<template>
    <a-modal
            :visible="visible"
            title='Thêm mới nhóm câu hỏi'
            okText='Thêm mới'
            cancelText="Hủy"
            @cancel="() => { $emit('cancel') }"
            @ok="() => { $emit('create') }"
    >
        <a-form layout='vertical' :form="form">
            <a-form-item label='Tên viết tắt'>
                <a-input
                        v-decorator="[
              'code',
              {
                rules: [{ required: true, message: 'Tên viết tắt không được bỏ trống!' }],
              }
            ]"
                />
            </a-form-item>
            <a-form-item label='Tên nhóm'>
                <a-input
                        v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Tên nhóm không được bỏ trống!' }],
              }
            ]"
                />
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    export default {
        name: "QuestionGroupForm",
        props: ['visible'],
        beforeCreate() {
                this.form = this.$form.createForm(this)
        }
    }
</script>