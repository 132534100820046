<template>
    <a-layout-content class="QuestionGroup">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px', padding: '15px' }">
            <div class="content-header">
                <h2>{{ this.$route.name }}</h2>
                <div class="content-header__left">
                    <a-button type="primary" @click="openModal" icon="plus-circle">Thêm nhóm</a-button>

                    <a-input-search
                            placeholder="Tìm kiếm..."
                            style="width: 200px; margin-left: 30px"
                            @change="(e) => this.search = e.target.value"
                    />
                </div>
            </div>
            <a-divider />

            <!--     Table question group       -->
            <a-table :columns="columns" :dataSource="groupFilter" bordered :rowKey="(record, index) => record.id" :loading="loading" :pagination="false">
                <template slot-scope="text">
                    <div :key="col">
                        <template>{{text}}</template>
                    </div>
                </template>

                <template v-for="col in ['code', 'name']" :slot="col" slot-scope="text, record">
                    <div :key="col">
                        <a-input
                                v-if="record.editable"
                                style="margin: -5px 0"
                                :value="text"
                                @change="e => handleChange(e.target.value, record.id, col)"
                        />
                        <template v-else>{{text}}</template>
                    </div>
                </template>
                <template slot="operation" slot-scope="text, record">
                    <div class='editable-row-operations'>
                        <span v-if="!record.editable"  style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                          <a-button @click="() => edit(record.id)">Cập nhật</a-button>
                        </span>

                        <span v-if="record.editable" style="margin-left: 10px; display: inline-block; margin-bottom: 10px;">
                            <a-popconfirm title='Bạn có chắc chắn?' @confirm="() => saveGroup(record)">
                                <a-button type="primary" style="display: inline-block;">Lưu lại</a-button>
                            </a-popconfirm>
                            <a-popconfirm title='Bạn có chắc chắn hủy?' @confirm="() => cancel(record.id)">
                                <a-button  style="margin-left: 10px">Hủy</a-button>
                            </a-popconfirm>
                        </span>
                    </div>
                </template>
            </a-table>
        </div>

        <!--    Modal add new    -->
        <question-group-form
                ref="questionGroupForm"
                :visible="visible"
                @cancel="handleCancel"
                @create="handleCreate"
        />
    </a-layout-content>
</template>

<script>
    import './_QuestionGroup.scss'
    import { mapActions, mapGetters } from "vuex"
    import QuestionGroupForm from "@/components/forms/QuestionGroupForm";
    import { RepositoryFactory } from "@/repository/RepositoryFactory";
    const questionGroupRepository = RepositoryFactory.get('questionGroup');

    const columns = [{
        title: 'Id nhóm',
        dataIndex: 'id',
        width: '5%',
        scopedSlots: { customRender: 'id' },
    }, {
        title: 'Code',
        dataIndex: 'code',
        width: '20%',
        scopedSlots: { customRender: 'code' },
    }, {
        title: 'Tên nhóm',
        dataIndex: 'name',
        width: '50%',
        scopedSlots: { customRender: 'name' },
    }, {
        title: 'Action',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
    }];

    export default {
        components: { QuestionGroupForm },
        data() {
            return {
                data: [],
                cacheData: [],
                visible: false,
                columns,
                loading: true,
                search: ''
            }
        },
        methods: {
            handleCancel() {
                this.visible = false;
            },
            handleCreate() {
                try {
                    this.loading = true;
                    const form = this.$refs.questionGroupForm.form;
                    form.validateFields(async (err, values) => {
                        if (err) {
                            return;
                        }

                        let { data } = await questionGroupRepository.createGroup(values);
                        if (!data.meta.success) throw new Error(data.meta.externalMessage);
                        let categories = this.getCategories;
                        categories.push(data.data);
                        this.setLocalCategories(categories);

                        form.resetFields();
                        this.visible = false;
                    });
                    this.$message.success('Thêm mới nhóm câu hỏi thành công!');
                } catch (e) {
                    this.openNotification(e.message)
                } finally {
                    this.loading = false;
                }
            },
            openModal() {
                this.visible = true;
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            edit(id) {
                const newData = [...this.getCategories];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target.editable = true;
                    this.setLocalCategories(newData);
                }
            },
            handleChange (value, id, column) {
                const newData = [...this.getCategories];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.setLocalCategories(newData);
                }
            },
            async saveGroup (record) {
                try {
                    this.loading = true;
                    let response = await questionGroupRepository.updateGroup(record.id, record);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage);
                    const newData = [...this.getCategories];
                    const target = newData.filter(item => record.id === item.id)[0];
                    if (target) {
                        delete target.editable;
                        this.setLocalCategories(newData);
                        this.cacheData = newData.map(item => ({ ...item }));
                    }
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
            cancel (id) {
                const newData = [...this.getCategories];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    Object.assign(target, this.cacheData.filter(item => id === item.id)[0])
                    delete target.editable;
                    this.setLocalCategories(newData);
                }
            },
            ...mapActions('staticData', [
                'setLocalCategories'
            ])
        },
        computed: {
            groupFilter: function () {
                return this.getCategories.map(item => Object.assign({}, item))
                    .filter(item => item.code.toUpperCase().includes(this.search.toUpperCase()) || item.name.toUpperCase().includes(this.search.toUpperCase()))
            },
            ...mapGetters('staticData', [
                'getCategories'
            ])
        },
        async created() {
            try {
                this.loading = true;
                this.data = this.getCategories;
                this.cacheData = this.getCategories.map(item => ({...item}));
            } catch (e) {
                this.openNotification(e.message)
            } finally {
                this.loading = false;
            }
        }
    };
</script>
